import React, { useEffect } from "react"
import * as styles from "./footer.module.scss"

import { StaticImage } from "gatsby-plugin-image"

import DemoButton from "./button-demo"

export default function Footer() {
  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.company}>
          <StaticImage
            className={styles.logo}
            src="../images/logo-nealle.svg"
            alt="ニーリーロゴ"
          />
          <a 
            href="https://www.nealle.com/"
            target="_target"
            rel="noopener noreferrer"
          >
            株式会社ニーリー
          </a>
          <p>
            〒103-0012
            <br />
            東京都中央区日本橋堀留町1-9-8
          </p>
        </div>
        <div className={styles.links}>
          <a
            href="https://www.nealle.com/security-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            セキュリティポリシー
          </a>
          <a
            href="https://www.park-direct.jp/privacy_policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
        </div>
      </div>
      <div className={styles.copylight}>
        <p>© Nealle Inc. All rights reserved.</p>
      </div>
      <div className={styles.fixedButton}>
        <DemoButton />
      </div>
    </footer>
  )
}

/**
 * スクロール検知
 */
function handleScroll() {
  const topY = document.getElementById('top').clientHeight / 3; // スタイル分岐点
  let footer = document.getElementsByTagName("footer")[0]

  const docY = document.body.clientHeight;
  const scrollCount = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollPos = document.documentElement.clientHeight + scrollCount;
  const footerY = document.querySelector('footer').clientHeight;

  if (
    (document.body.scrollTop > topY ||
    document.documentElement.scrollTop > topY) &&
    docY - scrollPos > footerY
  ) {
    if (footer != null) {
      footer.classList.add(styles.footer_scrolled)
    }
  } else {
    if (footer != null) {
      footer.classList.remove(styles.footer_scrolled)
    }
  }
}
