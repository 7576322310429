import React, { useEffect } from "react"
import * as styles from "./header.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import scrollTo from "gatsby-plugin-smoothscroll"

import ImgArrowDown from "../images/arrow-down.png"

export default function Header() {
  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })
  const breakpoints = useBreakpoint()
  // ヘッダーはdisplay:fixedにしているためコンテンツサイズに応じてブレイクポイントする
  return (
    <header>
      <div className={styles.container}>
        <div className={styles.symbols}>
          <div className={styles.img_park_direct} />
        </div>
        {breakpoints.sp ? (
          <div className={styles.menus}>
            <button
              onClick={() => scrollById("#benefit")}
            >
              <p>導入<br/>メリット</p>
              <img src={ImgArrowDown} alt="下矢印" />
            </button>
            <button
              onClick={() => scrollById("#simulation")}
            >
              <p>無料<br/>ｼﾐｭﾚｰｼｮﾝ</p>
              <img src={ImgArrowDown} alt="下矢印" />
            </button>
            <button
              onClick={() => scrollById("#feature")}
            >
              <p>機能詳細</p>
              <img src={ImgArrowDown} alt="下矢印" />
            </button>
            <button
              onClick={() => scrollById("#example")}
            >
              <p>導入事例</p>
              <img src={ImgArrowDown} alt="下矢印" />
            </button>
          </div>
        ) : (
          <div className={styles.menus}>
            <button
              onClick={() => scrollById("#benefit")}
            >
              導入メリット
            </button>
            <button
              onClick={() => scrollById("#simulation")}
            >
              無料シミュレーション
            </button>
            <button
              onClick={() => scrollById("#feature")}
            >
              機能詳細
            </button>
            <button
              onClick={() => scrollById("#example")}
            >
              導入事例
            </button>
          </div>
        )}
      </div>
    </header>
  )
}

/**
 * スクロール検知
 */
let pos = 0;

function handleScroll() {
  const scrollY = document.getElementById('top').clientHeight; // スタイル分岐点
  let header = document.getElementsByTagName("header")[0]
  if (
    document.body.scrollTop > scrollY ||
    document.documentElement.scrollTop > scrollY
  ) {
    if (header != null) {
      header.classList.add(styles.header_scrolled)
    }
  } else {
    if (header != null) {
      header.classList.remove(styles.header_scrolled)
    }
  }
  
  if (document.documentElement.scrollTop < pos && scrollY < pos) {
    header.classList.add(styles.header_scrolled_sp);
  } else {
    header.classList.remove(styles.header_scrolled_sp);
  }

  pos = document.documentElement.scrollTop;
}

/**
 * IDを指定したところにスクロール
 */
function scrollById(id) {
  // スクロール
  scrollTo(id)
}