import React, { useEffect } from "react";
import * as styles from "./section-contact-form.module.scss";

export default function ContactForm(props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    const onLoad = () => {
      window.hbspt?.forms.create({
        region: "na1",
        portalId: '44539551',
        formId: '62689b04-be97-42a8-aaaf-da3d09aa02a8',
        target: '#hubspotForm'
      });
    };

    script.addEventListener('load', onLoad);

    return () => {
      script.removeEventListener('load', onLoad);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={props.formType === "modal" ? styles.modal_contents : styles.contents}>
        <div id="hubspotForm"></div>
      </div>
    </div>
  );
}