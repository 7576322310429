import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import ogp_image from "../images/img-ogp.png"

function SEO({ title, description, lang, meta, siteUrl, keywords }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${ogp_image}`,
        },
        {
          property: `og:keywords`,
          content: keywords,
        },
        // Twitter
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `Nealle Inc.`,
        },
        {
          name: `twitter:image`,
          content: `${siteUrl}${ogp_image}`
        }
      ].concat(meta)}
    />
  )
}

// デフォルト値
SEO.defaultProps = {
  title: `法人車両・社用車の駐車場をオンラインで管理｜Park Direct for Business`,
  description: `法人車両・社用車・営業車の駐車場に関する業務（検索・契約・管理・支払い,車庫証明用書類取得など）すべてをオンライン化・アウトソーシングで大幅に効率化！業務負荷削減、コスト削減の無料シミュレーションも実施中！Park Direct for Business（パークダイレクトフォービジネス／パークダイレクトフォービズ）をぜひご導入ください。`,
  lang: `ja`,
  meta: [],
  siteUrl: `https://www.lp-biz.park-direct.jp`,
  keywords: `法人車両,社用車,営業車,駐車場管理,Park Direct for Business,パークダイレクトフォービジネス,パークダイレクトフォービズ,PDBiz,車庫証明,保管場所使用承諾証明書`
}

// 型指定
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  siteUrl: PropTypes.string,
  keywords: PropTypes.string,
}

export default SEO
