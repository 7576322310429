import React from "react"
import ReactDOM from "react-dom"
import * as styles from "./button-demo.module.scss"
import ContactForm from "./section-contact-form"
const { useState } = React

function Modal(props){
  return (
    <div className={styles.overlay}>
      <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
        <button className={styles.close_button} onClick={props.onClick}></button>
        <ContactForm formType="modal" />
      </div>
    </div>
  )
}

export default function DemoButton() {
  const [show, setShow] = useState(false)
  const openModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
    ReactDOM.render(<ContactForm />, document.getElementById("contact-form"));
  }

  return (
    <div>
      <button className={styles.button} type="button" onClick={openModal}>
        <div className={styles.content}>
          <p className={styles.title}>資料請求・お問い合わせ（無料）はこちら</p>
        </div>
      </button>
      {show? <Modal onClick={()=>{closeModal()}}/> :""}
    </div>
  )
}
