import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import ContactForm from "../components/section-contact-form"

import * as styles from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.scroll_content}>
          <Header />
          {children}
          <Footer />
      </div>
      <div className={styles.fixed_content} id="contact-form">
          <ContactForm />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
